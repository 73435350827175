<template>
  <v-list-group
    :group="group"
    :prepend-icon="item.icon"
    eager
    v-bind="$attrs"
    :disabled="disabled"
  >
    <template #activator>
      <v-list-item-icon
        v-if="!item.icon && !item.avatar"
        class="text-caption text-uppercase text-center my-2 align-self-center"
        style="margin-top: 14px"
      >
        {{ title }}
      </v-list-item-icon>

      <v-list-item-avatar v-if="item.avatar">
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-content v-if="item.title">
        <v-list-item-title>{{ item.title }} </v-list-item-title>
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in item.items">
      <default-list-group
        v-if="child.items"
        :key="`sub-group-${i}`"
        :disabled="disabled"
        :item="child"
      />

      <default-list-item
        v-if="!child.items"
        :key="`child-${i}`"
        :disabled="disabled"
        :item="child"
      />
    </template>
  </v-list-group>
</template>

<script lang="ts">
// Utilities

import { defineComponent } from "vue";
import DefaultListItem from "./ListItem.vue";

export default defineComponent({
  name: "DefaultListGroup",

  components: {
    DefaultListItem,
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    group() {
      return this.genGroup(this.item.items);
    },
    title() {
      const matches = this.item.title.match(/\b(\w)/g);

      return matches.join("");
    },
  },

  methods: {
    genGroup(items: any[]) {
      return items
        .reduce((acc: any[], cur: { to: string | any[]; items: any }) => {
          if (!cur.to) return acc;

          acc.push(cur.items ? this.genGroup(cur.items) : cur.to.slice(1, -1));

          return acc;
        }, [])
        .join("|");
    },
  },
});
</script>
