import Vue from "vue";
import * as Sentry from "@sentry/vue";
import router from "@/router";

const apiUrl: string = import.meta.env.VITE_APP_API_URL;
if (import.meta.env.DEV) {
  console.log("Sentry is disabled in development mode");
} else {
  Sentry.init({
    Vue,
    dsn: "https://5dce113fc518f2b8798bd5265465ad20@o4505651690536960.ingest.sentry.io/4505651690668032",
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration({
        failedRequestTargets: [new RegExp(`^${apiUrl.replace(/\./g, "\\.")}`)],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      new RegExp(`^${apiUrl.replace(/\./g, "\\.")}`),
    ],
  });
}
