import {
  type PluginObject,
  provide,
  type InjectionKey,
  inject,
  reactive,
  markRaw,
} from "vue";
import type { IConfig } from "./interfaces";
import { setupAuth } from "./composables";
import { instance, setInstance, type AuthInstance } from "./store";
import type VueRouter from "vue-router";
export type { AuthInstance } from "./store";

/**
 *  Vue Plugin Definition
 */
export const authSymbol: InjectionKey<AuthInstance> = Symbol("auth");
export const createAuth = (
  options?: IConfig,
  router?: VueRouter,
): PluginObject<{}> & { auth: AuthInstance } => {
  const auth: AuthInstance = setupAuth(options, router);
  setInstance(auth);
  return markRaw({
    auth,
    install: (Vue) => {
      Vue.prototype.$auth = reactive({ ...auth });
      Vue.mixin({
        setup() {
          provide(authSymbol, auth);
        },
      });
    },
  });
};

export const useAuth = () => {
  const auth = instance || inject(authSymbol);
  if (!auth) {
    throw new Error("No Auth0 instance found");
  }
  return auth;
};
