import Vue from "vue";
import { createAuth, type AuthInstance } from "@/auth";
import router from "@/router";
const apiUrl = import.meta.env.VITE_APP_API_URL;
export {};
declare module "vue" {
  interface ComponentCustomProperties {
    $auth: AuthInstance;
  }
}

export const auth = createAuth(
  {
    redirect: {
      login: "/login",
      logout: "/",
      callback: "/authorize-auth",
      home: "/",
    },
    strategy: {
      token: {
        property: "token",
        global: true,
        type: "Bearer",
        maxAge: 60 * 60 * 24 * 7,
      },
      user: {
        autoFetch: true,
      },
      endpoints: {
        login: { url: apiUrl + "/auth/signin", method: "post" },
        user: { url: apiUrl + "/user/me", method: "get" },
      },
    },
  },
  router,
);

Vue.use(auth);
