<template>
  <v-list expand nav v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, i) in items">
      <default-list-group
        v-if="item.items"
        :key="`group-${i}`"
        :item="item"
        :disabled="disabled"
      />

      <default-list-item
        v-else
        :key="`item-${i}`"
        :item="item"
        :disabled="disabled"
      />
    </template>
  </v-list>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import DefaultListGroup from "./ListGroup.vue";
import DefaultListItem from "./ListItem.vue";
export default defineComponent({
  name: "DefaultList",

  components: {
    DefaultListGroup,
    DefaultListItem,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
  },
});
</script>
